@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body {
  margin: 0;
  font-family:'Poppins', sans-serif;
  background-color: #FCFCFC;

}

code {
  font-family: 'Open Sans', sans-serif;
}


