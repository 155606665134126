.slick-prev:before,
.slick-next:before {
    display: none!important;
}
.slider-container {
    /* background-color: red!important;    */
    width: 100%;
}

.slide {
    background-color: #30475e; 
}

.slick-dots li button:before
{
    font-size: 20px!important;
    line-height: 20px!important;
}